import React, { useEffect, useState } from "react";
import { Box, IconButton } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import TransitionsModal from "../../components/Modal";
import AdminLayout from "../../layouts/AdminLayout";
import { BeatLoader } from "react-spinners";
import axios from "axios";
import { toast } from "react-toastify";
import { Icon } from "@iconify/react";
import { deleteApi, getApi, postApi } from "../../config/configAxios";
import Swal from "sweetalert2";
import CustomLoader from "../../components/CustomLoader";
import JoditEditor from "jodit-react";
import { config } from "../../utils/JoditConfig";
import TablePro from "../../components/DataTable/TablePro";

const Post = () => {
  const [data, setData] = useState([]);
  const [storeData, setStoreData] = useState({});
  const [editData, setEditData] = useState({});
  const [selectMenu, setSelectMenu] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openStore, setOpenStore] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const fetchData = async () => {
    try {
      const res = await getApi(`post`);
      setData(res.data.data);
    } catch (err) {
      toast.error(err?.message);
    } finally {
      setLoading(false);
    }
  };
  const fetchSelectMenu = async () => {
    try {
      const res = await getApi(`menu-client`);
      setSelectMenu(res.data.data);
    } catch (err) {
      toast.error(err?.message);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
    fetchSelectMenu();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setStoreData((prevData) => ({ ...prevData, [name]: value }));
  };

  /// store
  const handleStore = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const formData = new FormData();
    formData.append("menu_item_id", storeData.menu_item_id);
    formData.append("title", storeData.title);
    formData.append("content", storeData.content);
    try {
      await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}store-post`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setOpenStore(false);
      setStoreData({});
      fetchData();
      toast.success("Data created successfully!");
    } catch (err) {
      toast.error(err?.response?.data?.error || "update failed");
    } finally {
      setIsSubmitting(false);
    }
  };

  // delete
  const handleDelete = async (id) => {
    Swal.fire({
      title: "Confirm delete",
      text: "Are you sure you want to delete this?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          try {
            await deleteApi(`delete-post/${id}`)
              .then((res) => {
                toast.success("Data deleted successfully!");
              })
              .catch((err) => {
                toast.error("An error occurred while updating status");
              })
              .finally(() => {
                fetchData();
              });
          } catch (err) {
            toast.error(err?.response?.data?.error || "update failed");
          }
        } catch (error) {
          Swal.fire(
            "Error",
            "An error occurred while delete the user.",
            "error"
          );
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "The user was not deleted.", "info");
      }
    });
  };

  // edit
  const handleEdit = async (id) => {
    setOpenEdit(true);
    try {
      const res = await getApi(`find-post/${id}`);
      setEditData(res.data.data);
      setStoreData(res.data.data);
    } catch (err) {
      toast.error(err?.message);
    }
  };

  // handleUpdate
  const handleUpdate = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const formData = new FormData();
    formData.append("menu_item_id", storeData.menu_item_id);
    formData.append("title", storeData.title);
    formData.append("content", storeData.content);
    try {
      await postApi(`update-post/${editData.id}`, formData);
      setOpenEdit(false);
      setStoreData({});
      fetchData();
      toast.success("Data updated successfully!");
    } catch (err) {
      toast.error(err?.response?.data?.error || "update failed");
    } finally {
      setIsSubmitting(false);
    }
  };

  const columns = [
    {
      name: "sn",
      label: "SN",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "p_name",
      label: "Parent Menu",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "title",
      label: "Title",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "id",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => (
          <Box display="flex">
            <IconButton
              className="mr-3"
              onClick={() => handleDelete(value)}
            >
              <Delete className="text-red-500" />
            </IconButton>
            <IconButton
              className="mr-3"
              onClick={() => handleEdit(value)}
            >
              <Edit className="text-blue-500" />
            </IconButton>
          </Box>
        ),
      },
    },
  ];

  return (
    <AdminLayout title={"Post"}>
      {loading ? (
        <CustomLoader />
      ) : (
        <div>
          <div className="flex justify-between p-3">
            <h1 className="text-gray-700 font-medium">Post list</h1>
            <div>
              <button className="btn-indigo" onClick={() => setOpenStore(true)}>
                Add new
              </button>
            </div>
          </div>
          <div className="table-wrap">
            <TablePro
              title={"Post List"}
              data={data.map((item, index) => ({
                sn: index + 1,
                p_name: item?.parent_menu?.name,
                ...item,
              }))}
              columns={columns}
            />
          </div>
          {/* <div className="table-wrap">
            <table className="table-main shadow-md">
              <thead className="table-h">
                <tr>
                  <th className="table-h">SN</th>
                  <th className="table-h">Parent menu</th>
                  <th className="table-h">Title</th>
                  <th className="table-h">Action</th>
                </tr>
              </thead>
              <tbody className="table-b">
                {data.map((item, index) => (
                  <tr key={item.id}>
                    <td className="table-b">{index + 1}</td>
                    <td className="table-b">{item?.parent_menu?.name}</td>
                    <td className="table-b">{item.title}</td>
                    <td className="table-b">
                      <Box display="flex">
                        <IconButton
                          className="mr-3"
                          onClick={() => handleDelete(item.id)}
                        >
                          <Delete className="text-red-500" />
                        </IconButton>
                        <IconButton
                          className="mr-3"
                          onClick={() => handleEdit(item.id)}
                        >
                          <Edit className="text-blue-500" />
                        </IconButton>
                      </Box>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div> */}
        </div>
      )}
      {/* modal  */}

      <TransitionsModal title={"Add"} open={openStore} setOpen={setOpenStore}>
        <form className="" onSubmit={handleStore}>
          <div className="grid md:grid-cols-2 grid-cols-1 gap-6">
            <div className="md:col-span-2">
              <div className="md:col-span-2">
                <label htmlFor="menu_item_id" className="font-semibold text-sm">
                  Select category
                </label>
                <select
                  id="menu_item_id"
                  name="menu_item_id"
                  onChange={handleInputChange}
                  className="form-control max-h-16 overflow-y-scroll"
                >
                  <option className="p-2 rounded-md bottom-0" value="">
                    select
                  </option>
                  {selectMenu?.map((item) => (
                    <React.Fragment key={item.id}>
                      <option
                        className="p-2 font-semibold rounded-md bottom-0"
                        value={item.id}
                      >
                        {item.name}
                      </option>
                      {Array.isArray(item?.submenus) &&
                        item.submenus.length > 0 &&
                        item?.submenus?.map((submenu) => (
                          <React.Fragment key={submenu.id}>
                            <option
                              key={submenu.id}
                              className="p-2 rounded-md bg-slate-200"
                              value={submenu.id}
                            >
                              {">"}
                              {submenu.name}
                            </option>
                            {Array.isArray(submenu?.megamenus) &&
                              submenu.megamenus.length > 0 &&
                              submenu?.megamenus.map((megamenu) => (
                                <option
                                  key={megamenu.id}
                                  className="px-2 rounded-md pl-4"
                                  value={megamenu.id}
                                >
                                  {">>"}
                                  {megamenu.name}
                                </option>
                              ))}
                          </React.Fragment>
                        ))}
                    </React.Fragment>
                  ))}
                </select>
              </div>

              <label htmlFor="title" className="font-semibold text-sm">
                Title
              </label>
              <input
                type="text"
                id="title"
                name="title"
                onChange={handleInputChange}
                placeholder="title"
                className="form-control"
              />
            </div>
            <div className="md:col-span-2">
              <label htmlFor="content" className="font-semibold text-sm">
                Content
              </label>
              <JoditEditor
                config={config}
                tabIndex={1}
                value={storeData?.content}
                onBlur={(newContent) =>
                  setStoreData((prevData) => ({
                    ...prevData,
                    content: newContent,
                  }))
                }
              />
            </div>
            <div className="md:col-span-2">
              <button
                disabled={isSubmitting}
                type="submit"
                className="btn-green w-full"
                data-ripple-light="true"
              >
                {isSubmitting ? <BeatLoader color="#fff" /> : "Submit"}
              </button>
            </div>
          </div>
        </form>
      </TransitionsModal>
      <TransitionsModal title={"Edit"} open={openEdit} setOpen={setOpenEdit}>
        <form className="" onSubmit={handleUpdate}>
          <div className="grid md:grid-cols-2 grid-cols-1 gap-6">
            <div className="md:col-span-2">
              <label htmlFor="menu_item_id2" className="font-semibold text-sm">
                Select menu
              </label>
              <select
                id="menu_item_id"
                name="menu_item_id"
                onChange={handleInputChange}
                className="form-control"
                value={storeData?.menu_item_id}
              >
                <option className="p-2 rounded-md bottom-0" value="">
                  select
                </option>
                {selectMenu?.map((item) => (
                  <React.Fragment key={item.id}>
                    <option
                      selected={item?.id === storeData?.menu_item_id}
                      className="p-2 font-semibold rounded-md bottom-0"
                      value={item.id}
                    >
                      {item.name}
                    </option>
                    {Array.isArray(item?.submenus) &&
                      item.submenus.length > 0 &&
                      item?.submenus?.map((submenu) => (
                        <React.Fragment key={submenu.id}>
                          <option
                            key={submenu.id}
                            className="p-2 rounded-md bg-slate-200"
                            value={submenu.id}
                            selected={submenu?.id === storeData?.menu_item_id}
                          >
                            {">"}
                            {submenu.name}
                          </option>
                          {Array.isArray(submenu?.megamenus) &&
                            submenu.megamenus.length > 0 &&
                            submenu?.megamenus.map((megamenu) => (
                              <option
                                key={megamenu.id}
                                className="px-2 rounded-md pl-4"
                                value={megamenu.id}
                                selected={
                                  megamenu?.id === storeData?.menu_item_id
                                }
                              >
                                {">>"}
                                {megamenu.name}
                              </option>
                            ))}
                        </React.Fragment>
                      ))}
                  </React.Fragment>
                ))}
              </select>
            </div>
            <div className="md:col-span-2">
              <label htmlFor="title" className="font-semibold text-sm">
                Title
              </label>
              <input
                type="text"
                id="title"
                name="title"
                value={storeData?.title}
                onChange={handleInputChange}
                placeholder="title"
                className="form-control"
                required
              />
            </div>

            <div className="md:col-span-2">
              <label htmlFor="content" className="font-semibold text-sm">
                Content
              </label>
              <JoditEditor
                config={config}
                tabIndex={1}
                value={storeData?.content}
                onBlur={(newContent) =>
                  setStoreData((prevData) => ({
                    ...prevData,
                    content: newContent,
                  }))
                }
              />
            </div>
            <div className="md:col-span-2">
              <button
                disabled={isSubmitting}
                type="submit"
                className="btn-green w-full"
                data-ripple-light="true"
              >
                {isSubmitting ? <BeatLoader color="#fff" /> : "Update"}
              </button>
            </div>
          </div>
        </form>
      </TransitionsModal>
    </AdminLayout>
  );
};

export default Post;

