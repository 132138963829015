import React from "react";

const NotFound = () => {
  return (
    <div className="flex flex-row  justify-center items-center h-screen">
      <div className="text-center">
        <h1 className="font-semibold text-2xl">404</h1>
        <div>Page not found</div>
      </div>
    </div>
  );
};

export default NotFound;
