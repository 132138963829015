import React, { useEffect, useState } from "react";
import AdminLayout from "../layouts/AdminLayout";
import CustomLoader from "../components/CustomLoader";
import axios from "axios";
import { toast } from "react-toastify";
import { BeatLoader } from "react-spinners";

const CompanyInfo = () => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const fetchData = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}footer-company-info`
      );
      setData(res.data.data);
    } catch (err) {
      toast.error(err?.message);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const formData = new FormData();
    formData.append("company_name", data.company_name);
    formData.append("company_website", data.company_website);
    formData.append("fb", data.fb);
    formData.append("whatsapp", data.whatsapp);
    formData.append("yt", data.yt);
    formData.append("fb_page", data.fb_page);
    try {
      await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}update-footer-company-info/${data.id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      fetchData();
      toast.success("Data updated successfully!");
    } catch (err) {
      toast.error(err?.response?.data?.error || "update failed");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <AdminLayout title={"Footer Company and Social Media"}>
      {loading ? (
        <CustomLoader />
      ) : (
        <form className="" onSubmit={handleUpdate}>
          <div className="grid md:grid-cols-2 grid-cols-1 gap-6">
            <div>
              <label htmlFor="company_name" className="font-semibold text-sm">
                Company name
              </label>
              <input
                type="text"
                id="company_name"
                name="company_name"
                value={data?.company_name}
                onChange={handleInputChange}
                placeholder="company name"
                className="form-control"
              />
            </div>
            <div>
              <label
                htmlFor="company_website"
                className="font-semibold text-sm"
              >
                Company website
              </label>
              <input
                type="text"
                id="company_website"
                name="company_website"
                value={data?.company_website}
                onChange={handleInputChange}
                placeholder="company website"
                className="form-control"
              />
            </div>
            <div>
              <label htmlFor="fb" className="font-semibold text-sm">
                Facebook
              </label>
              <input
                type="text"
                id="fb"
                name="fb"
                value={data?.fb}
                onChange={handleInputChange}
                placeholder="facebook link"
                className="form-control"
              />
            </div>
            <div>
              <label htmlFor="fb_page" className="font-semibold text-sm">
                Facebook Page URL
              </label>
              <input
                type="text"
                id="fb_page"
                name="fb_page"
                value={data?.fb_page}
                onChange={handleInputChange}
                placeholder="facebook link"
                className="form-control"
              />
            </div>
            <div>
              <label htmlFor="whatsapp" className="font-semibold text-sm">
                WhatsApp
              </label>
              <input
                type="text"
                id="whatsapp"
                name="whatsapp"
                value={data?.whatsapp}
                onChange={handleInputChange}
                placeholder="whatsapp link"
                className="form-control"
              />
            </div>
            <div>
              <label htmlFor="yt" className="font-semibold text-sm">
                Youtube
              </label>
              <input
                type="text"
                id="yt"
                name="yt"
                value={data?.yt}
                onChange={handleInputChange}
                placeholder="youtube link"
                className="form-control"
              />
            </div>

            <div className="md:col-span-2">
              <div className="text-center">
                <button
                  disabled={isSubmitting}
                  type="submit"
                  className="btn-green px-16"
                  data-ripple-light="true"
                >
                  {isSubmitting ? <BeatLoader color="#fff" /> : "Update"}
                </button>
              </div>
            </div>
          </div>
        </form>
      )}
    </AdminLayout>
  );
};

export default CompanyInfo;
