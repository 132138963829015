import { Divider } from "@mui/material";
import React from "react";
import { MenuItem } from "react-pro-sidebar";
import { Link, useLocation } from "react-router-dom";

export const SubMenuItem = ({ title, url }) => {
  const location = useLocation();
  return (
    <>
      <Link to={url}>
        <MenuItem
          icon={
            <span
              className={location.pathname === url ? "dot-green" : "dot-slate"}
            />
          }
          className={
            location.pathname === url ? "font-semibold bg-slate-100" : ""
          }
        >
          {title}
        </MenuItem>
      </Link>

      <Divider />
    </>
  );
};
