import React, { useEffect, useState } from "react";
import { Box, IconButton } from "@mui/material";
import { Delete } from "@mui/icons-material";
import { toast } from "react-toastify";
import { BeatLoader } from "react-spinners";
import { Icon } from "@iconify/react/dist/iconify.js";
import Swal from "sweetalert2";
import AdminLayout from "../layouts/AdminLayout";
import CustomLoader from "../components/CustomLoader";
import TransitionsModal from "../components/Modal";
import { deleteApi, getApi, postApi, putApi } from "../config/configAxios";

const PoliceSubUnit = () => {
  const [data, setData] = useState([]);
  const [policeUnit, setPoliceUnit] = useState([]);
  const [loading, setLoading] = useState(true);
  const [name, setName] = useState("");
  const [selectedUnit, setSelectedUnit] = useState("");
  const [id, setId] = useState("");
  const [openStore, setOpenStore] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const fetchData = async () => {
    try {
      const response = await getApi("/police-sub-unit");
      setData(response.data?.data || []);
    } catch (error) {
      console.error("Error fetching police sub-units:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchPoliceUnit = async () => {
    try {
      const response = await getApi("/police-unit");
      setPoliceUnit(response.data?.data || []);
    } catch (error) {
      console.error("Error fetching police units:", error);
    }
  };

  useEffect(() => {
    fetchPoliceUnit();
    fetchData();
  }, []);

  const handleDelete = async (id) => {
    try {
      const result = await Swal.fire({
        text: "আপনি কি নিশ্চিত?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "না",
        confirmButtonText: "হ্যাঁ",
      });

      if (result.isConfirmed) {
        const response = await deleteApi(`/police-sub-unit/${id}`);
        setData((prevData) => prevData.filter((item) => item.id !== id));
        toast.success(response?.data?.message || "Deleted successfully");
      }
    } catch (error) {
      console.error("Error deleting police sub-unit:", error);
    }
  };

  useEffect(() => {
    if (id) {
      const fetchItem = async () => {
        try {
          const response = await getApi(`/police-sub-unit/${id}`);
          setName(response?.data?.data?.name || ""); // Ensure name is always a string
          setSelectedUnit(response?.data?.data?.police_unit_id || ""); // Ensure selectedUnit is always a string
        } catch (error) {
          console.error("Error fetching police sub-unit:", error);
        }
      };

      fetchItem();
    }
  }, [id]);

  const onClose = () => {
    setOpenStore(false);
    setId("");
    setName("");
    setSelectedUnit("");
    setIsSubmitting(false);
  };

  const onOpen = () => {
    setOpenStore(true);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = { name, police_unit_id: selectedUnit };
    setIsSubmitting(true);

    try {
      if (id) {
        await putApi(`/police-sub-unit/${id}`, formData);
        toast.success("Updated successfully");
      } else {
        await postApi("/police-sub-unit", formData);
        toast.success("Added successfully");
      }
      fetchData();
      onClose();
    } catch (error) {
      toast.error(error?.response?.data?.error || "Error");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <AdminLayout title={"District"}>
      {loading ? (
        <CustomLoader />
      ) : (
        <div>
          <div className="flex justify-between p-3">
            <h1 className="text-gray-700 font-medium">Districts</h1>
            <button className="btn-indigo" onClick={onOpen}>
              Add new
            </button>
          </div>
          <div className="table-wrap">
            <table className="table-main shadow-md">
              <thead className="table-h">
                <tr>
                  <th className="table-h">SN</th>
                  <th className="table-h">Unit</th>
                  <th className="table-h">Sub Unit</th>
                  <th className="table-h">Action</th>
                </tr>
              </thead>
              <tbody className="table-b">
                {data.map((item, index) => (
                  <tr key={item.id}>
                    <td className="table-b">{index + 1}</td>
                    <td className="table-b">{item.police_unit?.name}</td>
                    <td className="table-b">{item.name}</td>
                    <td className="table-b">
                      <Box display="flex">
                        <IconButton
                          className="mr-3"
                          onClick={() => handleDelete(item.id)}
                        >
                          <Delete className="text-red-500" />
                        </IconButton>
                        <IconButton
                          className="mr-3"
                          onClick={() => {
                            setId(item.id);
                            onOpen();
                          }}
                        >
                          <Icon icon="bxs:edit" className="text-blue-500" />
                        </IconButton>
                      </Box>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      <TransitionsModal
        title={id ? "Edit District" : "New District"}
        open={openStore}
        setOpen={setOpenStore}
      >
        <form onSubmit={handleSubmit}>
          <div className="grid md:grid-cols-2 grid-cols-1 gap-6">
            <div className="md:col-span-2">
              <label htmlFor="police_unit_id" className="font-semibold text-sm">
                Select Unit
              </label>
              <select
                id="police_unit_id"
                name="police_unit_id"
                value={selectedUnit}
                onChange={(e) => setSelectedUnit(e.target.value)}
                className="form-control max-h-16 overflow-y-scroll"
                required
              >
                <option value="">Select</option>
                {policeUnit.map((unit) => (
                  <option key={unit.id} value={unit.id}>
                    {unit.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="md:col-span-2">
              <label htmlFor="name" className="font-semibold text-sm">
                Name
              </label>
              <input
                type="text"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="form-control"
                required
              />
            </div>
            <div className="md:col-span-2">
              <button
                disabled={isSubmitting}
                type="submit"
                className="btn-green w-full"
                data-ripple-light="true"
              >
                {isSubmitting ? (
                  <BeatLoader color="#fff" />
                ) : id ? (
                  "Update"
                ) : (
                  "Create"
                )}
              </button>
            </div>
          </div>
        </form>
      </TransitionsModal>
    </AdminLayout>
  );
};

export default PoliceSubUnit;
