import React, { useEffect, useState } from "react";
import { Box, IconButton } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import TransitionsModal from "../components/Modal";
import AdminLayout from "../layouts/AdminLayout";
import { BeatLoader } from "react-spinners";
import axios from "axios";
import { toast } from "react-toastify";
import { Icon } from "@iconify/react";
import { deleteApi, getApi, postApi } from "../config/configAxios";
import Swal from "sweetalert2";
import CustomLoader from "../components/CustomLoader";

const SidebarSubCategory = () => {
  const [data, setData] = useState([]);
  const [storeData, setStoreData] = useState({});
  const [editData, setEditData] = useState({});
  const [heading, setHeading] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openStore, setOpenStore] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const fetchData = async () => {
    try {
      const res = await getApi(
        `${process.env.REACT_APP_API_BASE_URL}sidebar-sub-category`
      );
      setData(res.data.data);
    } catch (err) {
      toast.error(err?.message);
    } finally {
      setLoading(false);
    }
  };
  const fetchHeading = async () => {
    try {
      const res = await getApi(
        `${process.env.REACT_APP_API_BASE_URL}sidebar-category`
      );
      setHeading(res.data.data);
    } catch (err) {
      toast.error(err?.message);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
    fetchHeading();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setStoreData((prevData) => ({ ...prevData, [name]: value }));
  };

  /// store
  const handleStore = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const formData = new FormData();
    formData.append("sidebar_category_id", storeData.sidebar_category_id);
    formData.append("title", storeData.title);
    formData.append("link", storeData.link);
    try {
      await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}store-sidebar-sub-category`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setOpenStore(false);
      setStoreData({});
      fetchData();
      toast.success("Data created successfully!");
    } catch (err) {
      toast.error(err?.response?.data?.error || "update failed");
      console.log(err?.response?.data?.error);
    } finally {
      setIsSubmitting(false);
    }
  };
  // delete
  const handleDelete = async (id) => {
    Swal.fire({
      title: "Confirm delete",
      text: "Are you sure you want to delete this?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          try {
            await deleteApi(`delete-sidebar-sub-category/${id}`)
              .then((res) => {
                toast.success("Data deleted successfully!");
              })
              .catch((err) => {
                toast.error("An error occurred while updating");
              })
              .finally(() => {
                fetchData();
              });
          } catch (err) {
            toast.error(err?.response?.data?.error || "update failed");
          }
        } catch (error) {
          Swal.fire(
            "Error",
            "An error occurred while delete the user.",
            "error"
          );
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "The user was not deleted.", "info");
      }
    });
  };

  // edit
  const handleEdit = async (id) => {
    setOpenEdit(true);
    try {
      const res = await getApi(`find-sidebar-sub-category/${id}`);
      setEditData(res.data.data);
      setStoreData(res.data.data);
    } catch (err) {
      toast.error(err?.message);
    }
  };
  // handleUpdate
  const handleUpdate = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const formData = new FormData();
    formData.append("sidebar_category_id", storeData.sidebar_category_id);
    formData.append("title", storeData.title);
    formData.append("link", storeData.link);
    try {
      await postApi(
        `${process.env.REACT_APP_API_BASE_URL}update-sidebar-sub-category/${editData.id}`,
        formData
      );
      setOpenEdit(false);
      setStoreData({});
      fetchData();
      toast.success("Data updated successfully!");
    } catch (err) {
      toast.error(err?.response?.data?.error || "update failed");
    } finally {
      setIsSubmitting(false);
    }
  };
  return (
    <AdminLayout title={"Sidebar sub category"}>
      {loading ? (
        <CustomLoader />
      ) : (
        <div>
          <div className="flex justify-between p-3">
            <h1 className="text-gray-700 font-medium">
              Sidebar sub category list
            </h1>
            <div>
              <button className="btn-indigo" onClick={() => setOpenStore(true)}>
                Add new
              </button>
            </div>
          </div>
          <div className="table-wrap">
            <table className="table-main shadow-md">
              <thead className="table-h">
                <tr>
                  <th className="table-h">SN</th>
                  <th className="table-h">Heading</th>
                  <th className="table-h">Title</th>
                  <th className="table-h">Action</th>
                </tr>
              </thead>
              <tbody className="table-b">
                {data.map((item, index) => (
                  <tr key={item.id}>
                    <td className="table-b">{index + 1}</td>
                    <td className="table-b">{item?.sidebar_category?.title}</td>
                    <td className="table-b">{item.title}</td>
                    <td className="table-b">
                      <Box display="flex">
                        <IconButton
                          className="mr-3"
                          onClick={() => handleDelete(item.id)}
                        >
                          <Delete className="text-red-500" />
                        </IconButton>
                        <IconButton
                          className="mr-3"
                          onClick={() => handleEdit(item.id)}
                        >
                          <Edit className="text-blue-500" />
                        </IconButton>
                      </Box>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
      {/* modal  */}

      <TransitionsModal title={"Add"} open={openStore} setOpen={setOpenStore}>
        <form className="" onSubmit={handleStore}>
          <div className="grid md:grid-cols-2 grid-cols-1 gap-6">
            <div className="md:col-span-2">
              <div className="md:col-span-2">
                <label
                  htmlFor="sidebar_category_id"
                  className="font-semibold text-sm"
                >
                  Select heading
                </label>
                <select
                  id="sidebar_category_id"
                  name="sidebar_category_id"
                  onChange={handleInputChange}
                  placeholder="select heading"
                  className="form-control"
                  required
                >
                  <option className="p-2 rounded-md bottom-0" value="">
                    select
                  </option>
                  {heading?.map((item) => (
                    <option
                      key={item.id}
                      className="p-2 rounded-md bottom-0"
                      value={item.id}
                    >
                      {item.title}
                    </option>
                  ))}
                </select>
              </div>

              <label htmlFor="title" className="font-semibold text-sm">
                Title
              </label>
              <input
                type="text"
                id="title"
                name="title"
                onChange={handleInputChange}
                placeholder="title"
                className="form-control"
                required
              />
            </div>
            <div className="md:col-span-2">
              <label htmlFor="link" className="font-semibold text-sm">
                Link
              </label>
              <input
                type="text"
                id="link"
                name="link"
                onChange={handleInputChange}
                placeholder="link"
                className="form-control"
                required
              />
            </div>
            <div className="md:col-span-2">
              <button
                disabled={isSubmitting}
                type="submit"
                className="btn-green w-full"
                data-ripple-light="true"
              >
                {isSubmitting ? <BeatLoader color="#fff" /> : "Submit"}
              </button>
            </div>
          </div>
        </form>
      </TransitionsModal>
      <TransitionsModal title={"Edit"} open={openEdit} setOpen={setOpenEdit}>
        <form className="" onSubmit={handleUpdate}>
          <div className="grid md:grid-cols-2 grid-cols-1 gap-6">
            <div className="md:col-span-2">
              <label
                htmlFor="sidebar_category_id"
                className="font-semibold text-sm"
              >
                Select heading
              </label>
              <select
                id="sidebar_category_id"
                name="sidebar_category_id"
                onChange={handleInputChange}
                placeholder="select heading"
                className="form-control"
                required
              >
                <option className="p-2 rounded-md bottom-0" value="">
                  select
                </option>
                {heading?.map((item) => (
                  <option
                    key={item.id}
                    className="p-2 rounded-md bottom-0"
                    value={item.id}
                    selected={storeData?.sidebar_category_id === item.id}
                  >
                    {item.title}
                  </option>
                ))}
              </select>
            </div>
            <div className="md:col-span-2">
              <label htmlFor="title" className="font-semibold text-sm">
                Title
              </label>
              <input
                type="text"
                id="title"
                name="title"
                value={storeData?.title}
                onChange={handleInputChange}
                placeholder="title"
                className="form-control"
                required
              />
            </div>
            <div className="md:col-span-2">
              <label htmlFor="link" className="font-semibold text-sm">
                Link
              </label>
              <input
                type="text"
                id="link"
                name="link"
                value={storeData?.link}
                onChange={handleInputChange}
                placeholder="link"
                className="form-control"
                required
              />
            </div>
            <div className="md:col-span-2">
              <button
                disabled={isSubmitting}
                type="submit"
                className="btn-green w-full"
                data-ripple-light="true"
              >
                {isSubmitting ? <BeatLoader color="#fff" /> : "Update"}
              </button>
            </div>
          </div>
        </form>
      </TransitionsModal>
    </AdminLayout>
  );
};

export default SidebarSubCategory;
