import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  IconButton,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import AdminLayout from "../../layouts/AdminLayout";

const ChangePassword = () => {
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);

  const initialValues = {
    old_password: "",
    new_password: "",
  };

  const validationSchema = Yup.object({
    old_password: Yup.string()
      .min(6, "New password must be at least 6 characters")
      .required("Old password is required"),
    new_password: Yup.string()
      .min(6, "New password must be at least 6 characters")
      .required("New password is required"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, { setSubmitting, setErrors, resetForm }) => {
      try {
        setSubmitting(true);
        const response = await axios.post("change-password", values);
        toast.success(response.data.message);
        resetForm();
        setSubmitting(false);
      } catch (error) {
        if (error.response && error.response.data) {
          setErrors({ old_password: error.response.data.message });
        }
        setSubmitting(false);
      }
    },
  });

  const handleClickShowOldPassword = () => {
    setShowOldPassword((show) => !show);
  };

  const handleClickShowNewPassword = () => {
    setShowNewPassword((show) => !show);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <AdminLayout title=" Change Password">
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="80vh"
        bgcolor={"#e7e7e7"}
      >
        <Box p={4} bgcolor={"#fafcfe"} borderRadius={"5px"}>
          <h1 className="text-2xl font-bold text-gray-800 mb-6">
            Change Password
          </h1>
          <form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
            <Box pt={2}>
              <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="old-password">Old Password</InputLabel>
                <OutlinedInput
                  id="old-password"
                  type={showOldPassword ? "text" : "password"}
                  name="old_password"
                  value={formik.values.old_password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.old_password &&
                    Boolean(formik.errors.old_password)
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle old password visibility"
                        onClick={handleClickShowOldPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showOldPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Old Password"
                />
              </FormControl>
              {formik.errors.old_password && formik.touched.old_password && (
                <p style={{ color: "#d32f2f", fontSize: "13px" }}>
                  {formik.errors.old_password}
                </p>
              )}
            </Box>
            <Box py={3}>
              <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="new-password">New Password</InputLabel>
                <OutlinedInput
                  id="new-password"
                  type={showNewPassword ? "text" : "password"}
                  name="new_password"
                  value={formik.values.new_password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.new_password &&
                    Boolean(formik.errors.new_password)
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle new password visibility"
                        onClick={handleClickShowNewPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showNewPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="New Password"
                />
              </FormControl>
              {formik.errors.new_password && formik.touched.new_password && (
                <p style={{ color: "#d32f2f", fontSize: "13px" }}>
                  {formik.errors.new_password}
                </p>
              )}
            </Box>
            <Button
              fullWidth
              size="large"
              type="submit"
              disabled={formik.isSubmitting}
              variant="contained"
              color="primary"
              sx={{ textTransform: "capitalize", mt: 2 }}
            >
              {formik.isSubmitting ? "Changing..." : "Change Password"}
            </Button>
          </form>
        </Box>
      </Box>
    </AdminLayout>
  );
};

export default ChangePassword;
