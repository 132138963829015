import { Delete, Edit } from "@mui/icons-material";
import { Box, Checkbox, IconButton } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import CustomLoader from "../../components/CustomLoader";
import TransitionsModal from "../../components/Modal";
import { deleteApi, getApi } from "../../config/configAxios";
import AdminLayout from "../../layouts/AdminLayout";
import { renderLocalDate } from "../../utils/localDate";

const MenuPost = () => {
  const params = useParams();
  const [manu, setMenu] = useState([]);
  const [menuData, setMenuData] = useState({});
  const [storeData, setStoreData] = useState({ table_sub_menu_id: [] });
  const [editData, setEditData] = useState({});
  const [loading, setLoading] = useState(true);
  const [openStore, setOpenStore] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const fetchMenuWithPost = async () => {
    setLoading(true);
    try {
      const res = await getApi(`find-table-menu-with-post/${params?.id}`);
      setMenuData(res.data.data);
    } catch (err) {
      toast.error(err?.message);
    } finally {
      setLoading(false);
    }
  };
  const onOpenStore = () => {
    setOpenStore(true);
    setStoreData({ table_sub_menu_id: [] });
  };
  const fetchMenu = async () => {
    try {
      const res = await getApi(`table-menu/${params?.id}`);
      setMenu(res.data.data);
    } catch (err) {
      toast.error(err?.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMenu();
    fetchMenuWithPost();
  }, [params?.id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setStoreData((prevData) => ({ ...prevData, [name]: value }));
  };

  /// store
  const handleStore = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const formData = new FormData();
    storeData.table_sub_menu_id.forEach((id) => {
      formData.append("table_sub_menu_id[]", id); // Append each ID as an array item
    });
    formData.append("table_menu_id", params.id);
    formData.append("title", storeData.title);
    formData.append("news", storeData.news);
    formData.append("file", storeData.file);
    try {
      await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}store-table-menu-post`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setOpenStore(false);
      setStoreData({ table_sub_menu_id: [] });
      fetchMenuWithPost();
      toast.success("Data created successfully!");
    } catch (err) {
      toast.error(err?.response?.data?.error || "failed");
    } finally {
      setIsSubmitting(false);
    }
  };

  // delete
  const handleDelete = async (id) => {
    Swal.fire({
      title: "Confirm delete",
      text: "Are you sure you want to delete this?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          try {
            await deleteApi(`delete-table-menu-post/${id}`)
              .then((res) => {
                toast.success("Data deleted successfully!");
              })
              .catch((err) => {
                toast.error(err?.response?.data?.error || "failed");
              })
              .finally(() => {
                fetchMenuWithPost();
              });
          } catch (err) {
            toast.error(err?.response?.data?.error || "update failed");
          }
        } catch (error) {
          Swal.fire(
            "Error",
            "An error occurred while delete the user.",
            "error"
          );
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "The user was not deleted.", "info");
      }
    });
  };

  // edit
  const handleEdit = async (id) => {
    setOpenEdit(true);
    try {
      const res = await getApi(`find-table-menu-post/${id}`);
      const mainData = res.data.data;
      setEditData(res.data.data);
      setStoreData({
        ...mainData,
        title: mainData?.title,
        file: mainData?.file,
        table_menu_id: mainData?.table_menu_id,
        news: mainData?.news,
        //
        table_sub_menu_id: mainData.table_sub_menus.map((item) => item.id),
      });
    } catch (err) {
      toast.error(err?.message);
    }
  };

  // handleUpdate
  const handleUpdate = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    
    const formData = new FormData();
    storeData.table_sub_menu_id.forEach((id) => {
      formData.append("table_sub_menu_id[]", id); // Append each ID as an array item
    });
    formData.append("table_menu_id", params.id);
    formData.append("title", storeData.title);
    formData.append("file", storeData.file);
    formData.append("news", storeData.news);
    try {
      await axios.post(`update-table-menu-post/${editData.id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setOpenEdit(false);
      setStoreData({ table_sub_menu_id: [] });
      fetchMenuWithPost();
      toast.success("Data updated successfully!");
    } catch (err) {
      toast.error(err?.response?.data?.error || "update failed");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setStoreData((prevData) => ({ ...prevData, file: file }));
  };
  const handleCheckboxChangePublished = (e) => {
    const { value, checked } = e.target;
    setStoreData((prevData) => ({
      ...prevData,
      news: checked ? value : "", // or handle unchecked state as needed
    }));
  };

  const handleCheckboxChange = (e) => {
    const event = e.target;
    const value = Number(event.value);
    const checked = event.checked;

    setStoreData((prevData) => {
      let updatedTableSubMenuId = [...prevData.table_sub_menu_id];

      if (checked) {
        // Add the value if it's not already included
        if (!updatedTableSubMenuId.includes(value)) {
          updatedTableSubMenuId.push(value);
        }
      } else {
        // Remove the value if it exists
        updatedTableSubMenuId = updatedTableSubMenuId.filter(
          (id) => id !== value
        );
      }

      return {
        ...prevData,
        table_sub_menu_id: updatedTableSubMenuId,
      };
    });
  };

  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  return (
    <AdminLayout title={menuData?.name}>
      {loading ? (
        <CustomLoader />
      ) : (
        <div>
          <div className="flex justify-between p-3">
            <h1 className="text-gray-700 font-medium">{menuData?.name} list</h1>
            <div>
              <button className="btn-indigo" onClick={() => onOpenStore()}>
                Add new
              </button>
            </div>
          </div>
          <div className="table-wrap">
            <table className="table-main shadow-md">
              <thead className="table-h">
                <tr>
                  <th className="table-h">SN</th>
                  <th className="table-h">Title</th>
                  <th className="table-h">Date</th>
                  <th className="table-h">Action</th>
                </tr>
              </thead>
              <tbody className="table-b">
                {menuData?.menu_posts?.map((item, index) => (
                  <tr key={item.id}>
                    <td className="table-b">{index + 1}</td>
                    <td className="table-b">{item.title}</td>
                    <td className="table-b">
                      {renderLocalDate(item.created_at)}
                    </td>
                    <td className="table-b">
                      <Box display="flex">
                        <IconButton
                          className="mr-3"
                          onClick={() => handleDelete(item.id)}
                        >
                          <Delete className="text-red-500" />
                        </IconButton>
                        <IconButton
                          className="mr-3"
                          onClick={() => handleEdit(item.id)}
                        >
                          <Edit className="text-blue-500" />
                        </IconButton>
                      </Box>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
      {/* modal  */}

      <TransitionsModal title={"Add"} open={openStore} setOpen={setOpenStore}>
        <form className="" onSubmit={handleStore}>
          <div className="grid md:grid-cols-2 grid-cols-1 gap-6">
            <div className="md:col-span-2">
              <label
                htmlFor="table_sub_menu_id"
                className="font-semibold text-sm"
              >
                Select menu
              </label>
              <div className="flex flex-wrap items-center gap-3">
                <div>
                  <Checkbox
                    {...label}
                    color="success"
                    value={"published"}
                    checked={storeData.news === "published"}
                    onChange={handleCheckboxChangePublished}
                  />
                  Latest news
                </div>
                {manu?.map((item) => (
                  <div key={item.id}>
                    <Checkbox
                      {...label}
                      color="success"
                      value={item.id}
                      checked={storeData.table_sub_menu_id.includes(item.id)}
                      onChange={handleCheckboxChange}
                    />
                    {item.name}
                  </div>
                ))}
              </div>
            </div>
            <div className="md:col-span-2">
              <label htmlFor="title" className="font-semibold text-sm">
                Title
              </label>
              <input
                type="text"
                id="title"
                name="title"
                onChange={handleInputChange}
                placeholder="title"
                className="form-control"
              />
            </div>

            <div className="md:col-span-2">
              <label htmlFor="title" className="font-semibold text-sm">
                File
              </label>
              <input
                type="file"
                id="file"
                name="file"
                onChange={handleFileChange}
                placeholder="file"
                className="form-control"
                required
              />
            </div>

            <div className="md:col-span-2">
              <button
                disabled={isSubmitting}
                type="submit"
                className="btn-green w-full"
                data-ripple-light="true"
              >
                {isSubmitting ? <BeatLoader color="#fff" /> : "Submit"}
              </button>
            </div>
          </div>
        </form>
      </TransitionsModal>
      <TransitionsModal title={"Edit"} open={openEdit} setOpen={setOpenEdit}>
        <form className="" onSubmit={handleUpdate}>
          <div className="grid md:grid-cols-2 grid-cols-1 gap-6">
            <div className="md:col-span-2">
              <label
                htmlFor="table_sub_menu_id"
                className="font-semibold text-sm"
              >
                Select menu
              </label>
              <div className="flex flex-wrap items-center gap-3">
                <div>
                  <Checkbox
                    {...label}
                    color="success"
                    value={"published"}
                    checked={storeData.news === "published"}
                    onChange={handleCheckboxChangePublished}
                  />
                  Latest news
                </div>
                {manu?.map((item) => (
                  <div key={item.id}>
                    <Checkbox
                      {...label}
                      color="success"
                      value={item.id}
                      checked={storeData.table_sub_menu_id.includes(item.id)}
                      onChange={handleCheckboxChange}
                    />
                    {item.name}
                  </div>
                ))}
              </div>
            </div>
            <div className="md:col-span-2">
              <label htmlFor="title" className="font-semibold text-sm">
                Title
              </label>
              <input
                type="text"
                id="title"
                name="title"
                value={storeData?.title}
                onChange={handleInputChange}
                placeholder="title"
                className="form-control"
                required
              />
            </div>
            <div className="md:col-span-2">
              <label htmlFor="title" className="font-semibold text-sm">
                File
              </label>
              <input
                type="file"
                id="file"
                name="file"
                onChange={handleFileChange}
                placeholder="file"
                className="form-control"
              />
            </div>

            <div className="md:col-span-2">
              <button
                disabled={isSubmitting}
                type="submit"
                className="btn-green w-full"
                data-ripple-light="true"
              >
                {isSubmitting ? <BeatLoader color="#fff" /> : "Update"}
              </button>
            </div>
          </div>
        </form>
      </TransitionsModal>
    </AdminLayout>
  );
};

export default MenuPost;
