import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import DashboardCard from "../components/DashboardCard/DashboardCard";
import AdminLayout from "../layouts/AdminLayout";
import axios from "axios";
import CustomLoader from "../components/CustomLoader";
import { useSelector } from "react-redux";

const Dashboard = () => {
  const token = useSelector(state => state.auth.token);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    totalUsers: 0,
    totalActivities: 0,
    totalNotices: 0,
    totalImages: 0,
    totalVideos: 0,
    recentActivities: 0,
  });

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get("dashboard");
      setData(response.data);
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    fetchData();
  }, [token]);

  if (loading) {
    return <CustomLoader />;
  }

  return (
    <AdminLayout title="Dashboard">
      <Grid container spacing={1}>
        <Grid item xs={12} md={4}>
          <DashboardCard
            icon={"humbleicons:users"}
            title={"Users"}
            subTitle={"Total"}
            countNumber={data.totalUsers}
            color={"#F4D160"}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <DashboardCard
            icon={"streamline:shopping-store-2-store-shop-shops-stores"}
            title={"Activities"}
            subTitle={"Total"}
            countNumber={data.totalActivities}
            color={"#2980b9"}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <DashboardCard
            icon={"fa:users"}
            title={"Notices"}
            subTitle={"Total"}
            countNumber={data.totalNotices}
            color={"#27ae60"}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <DashboardCard
            icon={"tdesign:building"}
            title={"Images"}
            subTitle={"Total"}
            countNumber={data.totalImages}
            color={"#FF6AC2"}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <DashboardCard
            icon={"bx:video"}
            title={"Videos"}
            subTitle={"Total"}
            countNumber={data.totalVideos}
            color={"#ff0000"}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <DashboardCard
            icon={"clarity:building-line"}
            title={"Recent Activities"}
            subTitle={"Total"}
            countNumber={data.recentActivities}
            color={"#ff0000"}
          />
        </Grid>
      </Grid>
    </AdminLayout>
  );
};

export default Dashboard;
