import React, { useEffect, useState } from "react";
import { Box, IconButton } from "@mui/material";
import { Delete, Edit, NextPlan } from "@mui/icons-material";
import TransitionsModal from "../../components/Modal";
import AdminLayout from "../../layouts/AdminLayout";
import { BeatLoader } from "react-spinners";
import axios from "axios";
import { toast } from "react-toastify";
import { Icon } from "@iconify/react";
import { deleteApi, getApi } from "../../config/configAxios";
import Swal from "sweetalert2";
import CustomLoader from "../../components/CustomLoader";

const MenuItem = () => {
  const [data, setData] = useState([]);
  const [selectMenu, setSelectMenu] = useState([]);
  const [storeData, setStoreData] = useState({
    parent_id: "",
    name: "",
    link: "",
    grid_col: "",
    index: "",
    color: "",
  });
  const [editData, setEditData] = useState({
    parent_id: "",
    name: "",
    link: "",
    grid_col: "",
    index: "",
    color: "",
  });
  const [loading, setLoading] = useState(true);
  const [openStore, setOpenStore] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const fetchData = async () => {
    try {
      const res = await getApi(`menu-item`);
      setData(res.data.data);
    } catch (err) {
      toast.error(err?.message);
    } finally {
      setLoading(false);
    }
  };
  const fetchSelectMenu = async () => {
    try {
      const res = await getApi(`menu-client`);
      setSelectMenu(res.data.data);
    } catch (err) {
      toast.error(err?.message);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
    fetchSelectMenu();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setStoreData((prevData) => ({ ...prevData, [name]: value }));
  };

  /// store
  const handleStore = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const formData = new FormData();
    formData.append("parent_id", storeData.parent_id || "");
    formData.append("name", storeData.name);
    formData.append("color", storeData.color);
    formData.append("link", storeData.link);
    formData.append("index", storeData.index);
    formData.append("grid_col", storeData.grid_col);
    try {
      await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}store-menu-item`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      fetchData();
      fetchSelectMenu();
      setOpenStore(false);
      toast.success("Data created successfully!");
      setStoreData({
        parent_id: "",
        name: "",
        link: "",
        grid_col: "",
        index: "",
        color: "",
      });
    } catch (err) {
      toast.error(err?.response?.data?.error || "failed");
    } finally {
      setIsSubmitting(false);
    }
  };
  // delete
  const handleDelete = async (id) => {
    Swal.fire({
      title: "Confirm delete",
      text: "Are you sure you want to delete this?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          try {
            await deleteApi(`delete-menu-item/${id}`)
              .then((res) => {
                toast.success("Data deleted successfully!");
              })
              .catch((err) => {
                toast.error(err?.response?.data?.error || "update failed");
              })
              .finally(() => {
                fetchSelectMenu();
                fetchData();
              });
          } catch (err) {
            toast.error(err?.response?.data?.error || "update failed");
          }
        } catch (error) {
          Swal.fire(
            "Error",
            "An error occurred while delete the user.",
            "error"
          );
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "The user was not deleted.", "info");
      }
    });
  };

  // edit
  const handleEdit = async (id) => {
    setOpenEdit(true);
    try {
      const res = await getApi(`find-menu-item/${id}`);
      setEditData(res.data.data);
      setStoreData(res.data.data);
    } catch (err) {
      toast.error(err?.message);
    }
  };

  // handleUpdate
  const handleUpdate = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const formData = new FormData();
    formData.append("parent_id", storeData.parent_id || "");
    formData.append("name", storeData.name);
    formData.append("color", storeData.color);
    formData.append("link", storeData.link);
    formData.append("index", storeData.index);
    formData.append("grid_col", storeData.grid_col);
    try {
      await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}update-menu-item/${editData.id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      fetchData();
      fetchSelectMenu();
      setOpenEdit(false);
      setStoreData({
        parent_id: "",
        name: "",
        link: "",
        grid_col: "",
        index: "",
        color: "",
      });
      toast.success("Data updated successfully!");
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.error || "update failed");
    } finally {
      setIsSubmitting(false);
    }
  };
  return (
    <AdminLayout title={"Menu"}>
      {loading ? (
        <CustomLoader />
      ) : (
        <div>
          <div className="flex justify-between p-3">
            <h1 className="text-gray-700 font-medium">Menu</h1>
            <div>
              <button className="btn-indigo" onClick={() => setOpenStore(true)}>
                Add new
              </button>
            </div>
          </div>
          <div className="table-wrap">
            <table className="table-main shadow-md">
              <thead className="table-h">
                <tr>
                  <th className="table-h">SN</th>
                  <th className="table-h">Name</th>
                  <th className="table-h">Menu type</th>
                  <th className="table-h">Column</th>
                  <th className="table-h">Index</th>
                  <th className="table-h">Action</th>
                </tr>
              </thead>
              <tbody className="table-b">
                {selectMenu?.map((item, index) => (
                  <React.Fragment key={item.id}>
                    <tr key={item.id} className="bg-green-50">
                      <td className="table-b">{index + 1}</td>
                      <td className="table-b" style={{ color: item.color }}>
                        {item.name}
                      </td>
                      <td className="table-b">
                        <span className="badge-indigo">parent</span>
                      </td>
                      <td className="table-b">{item.grid_col}</td>
                      <td className="table-b">{item.index}</td>
                      <td className="table-b">
                        <Box display="flex">
                          <IconButton
                            className="mr-3"
                            onClick={() => handleDelete(item.id)}
                          >
                            <Delete className="text-red-500" />
                          </IconButton>
                          <IconButton
                            className="mr-3"
                            onClick={() => handleEdit(item.id)}
                          >
                            <Edit className="text-blue-500" />
                          </IconButton>
                        </Box>
                      </td>
                    </tr>
                    {Array.isArray(item?.submenus) &&
                      item.submenus.length > 0 &&
                      item?.submenus?.map((submenu, index) => (
                        <React.Fragment key={submenu.id}>
                          <tr key={submenu.id}>
                            <td className="table-b"><NextPlan /></td>
                            <td
                              className="table-b"
                              style={{ color: submenu.color }}
                            >
                              {submenu.name}
                            </td>
                            <td className="table-b">
                              <span className="badge-orange">submenu</span>
                            </td>
                            <td className="table-b">{submenu.grid_col}</td>
                            <td className="table-b">{submenu.index}</td>
                            <td className="table-b">
                              <Box display="flex">
                                <IconButton
                                  className="mr-3"
                                  onClick={() => handleDelete(submenu.id)}
                                >
                                  <Delete className="text-red-500" />
                                </IconButton>
                                <IconButton
                                  className="mr-3"
                                  onClick={() => handleEdit(submenu.id)}
                                >
                                  <Icon
                                    icon={"bxs:edit"}
                                    className="text-blue-500"
                                  />
                                </IconButton>
                              </Box>
                            </td>
                          </tr>
                          {Array.isArray(submenu?.megamenus) &&
                            submenu.megamenus.length > 0 &&
                            submenu?.megamenus.map((megamenu, index) => (
                              <tr key={megamenu.id}>
                                <td className="table-b">{index + 1}</td>
                                <td
                                  className="table-b"
                                  style={{ color: megamenu.color }}
                                >
                                  {megamenu.name}
                                </td>
                                <td className="table-b">
                                  <span className="badge-green">megamenu</span>
                                </td>
                                <td className="table-b">{megamenu.grid_col}</td>
                                <td className="table-b">{megamenu.index}</td>
                                <td className="table-b">
                                  <Box display="flex">
                                    <IconButton
                                      className="mr-3"
                                      onClick={() => handleDelete(megamenu.id)}
                                    >
                                      <Delete className="text-red-500" />
                                    </IconButton>
                                    <IconButton
                                      className="mr-3"
                                      onClick={() => handleEdit(megamenu.id)}
                                    >
                                      <Edit
                                        className="text-blue-500"
                                      />
                                    </IconButton>
                                  </Box>
                                </td>
                              </tr>
                            ))}
                        </React.Fragment>
                      ))}
                  </React.Fragment>
                ))}
                
              </tbody>
            </table>
          </div>
        </div>
      )}
      {/* modal  */}

      <TransitionsModal title={"Add"} open={openStore} setOpen={setOpenStore}>
        <form className="" onSubmit={handleStore}>
          <div className="grid md:grid-cols-2 grid-cols-1 gap-6">
            <div className="md:col-span-1">
              <label htmlFor="parent_id" className="font-semibold text-sm">
                Select menu
              </label>
              <select
                id="parent_id"
                name="parent_id"
                onChange={handleInputChange}
                className="form-control max-h-16 overflow-y-scroll"
              >
                <option className="p-2 rounded-md bottom-0" value="">
                  select
                </option>
                {selectMenu?.map((item) => (
                  <React.Fragment key={item.id}>
                    <option
                      className="p-2 font-semibold rounded-md bottom-0"
                      value={item.id}
                    >
                      {item.name}
                    </option>
                    {Array.isArray(item?.submenus) &&
                      item.submenus.length > 0 &&
                      item?.submenus?.map((submenu) => (
                        <React.Fragment key={submenu.id}>
                          <option
                            key={submenu.id}
                            className="p-2 rounded-md bg-slate-200"
                            value={submenu.id}
                          >
                            {">"}
                            {submenu.name}
                          </option>
                          {Array.isArray(submenu?.megamenus) &&
                            submenu.megamenus.length > 0 &&
                            submenu?.megamenus.map((megamenu) => (
                              <option
                                key={megamenu.id}
                                className="px-2 rounded-md pl-4"
                                value={megamenu.id}
                                disabled
                              >
                                {">>"}
                                {megamenu.name}
                              </option>
                            ))}
                        </React.Fragment>
                      ))}
                  </React.Fragment>
                ))}
              </select>
            </div>
            <div className="md:col-span-1">
              <label htmlFor="name" className="font-semibold text-sm">
                Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                onChange={handleInputChange}
                placeholder="name"
                className="form-control"
                required
              />
            </div>
            <div className="md:col-span-1">
              <label htmlFor="link" className="font-semibold text-sm">
                Link
              </label>
              <input
                type="text"
                id="link"
                name="link"
                onChange={handleInputChange}
                placeholder="link"
                className="form-control"
                required
              />
            </div>
            <div className="md:col-span-1">
              <label htmlFor="grid_col" className="font-semibold text-sm">
                Cloumn
              </label>
              <input
                type="number"
                id="grid_col"
                name="grid_col"
                onChange={handleInputChange}
                placeholder="grid_col"
                className="form-control"
                required
              />
            </div>
            <div className="md:col-span-1">
              <label htmlFor="index" className="font-semibold text-sm">
                Index
              </label>
              <input
                type="number"
                id="index"
                name="index"
                onChange={handleInputChange}
                placeholder="index"
                className="form-control"
                required
              />
            </div>
            <div className="md:col-span-1">
              <label htmlFor="color" className="font-semibold text-sm">
                Color
              </label>
              <input
                type="color"
                id="color"
                name="color"
                onChange={handleInputChange}
                placeholder="color"
                className="form-control h-32"
                required
              />
            </div>
            <div className="md:col-span-2">
              <button
                disabled={isSubmitting}
                type="submit"
                className="btn-green w-full"
                data-ripple-light="true"
              >
                {isSubmitting ? <BeatLoader color="#fff" /> : "Submit"}
              </button>
            </div>
          </div>
        </form>
      </TransitionsModal>
      <TransitionsModal title={"Edit"} open={openEdit} setOpen={setOpenEdit}>
        <form className="" onSubmit={handleUpdate}>
          <div className="grid md:grid-cols-2 grid-cols-1 gap-6">
            <div className="md:col-span-1">
              <label htmlFor="parent_id" className="font-semibold text-sm">
                Select menu
              </label>
              <select
                id="parent_id"
                name="parent_id"
                onChange={handleInputChange}
                className="form-control"
              >
                <option className="p-2 rounded-md bottom-0" value="">
                  select
                </option>
                {selectMenu?.map((item) => (
                  <React.Fragment key={item.id}>
                    <option
                      selected={item?.id === storeData?.parent_id}
                      className="p-2 font-semibold rounded-md bottom-0"
                      value={item.id}
                      disabled={item.id === storeData?.id}
                    >
                      {item.name}
                    </option>
                    {Array.isArray(item?.submenus) &&
                      item.submenus.length > 0 &&
                      item?.submenus?.map((submenu) => (
                        <React.Fragment key={submenu.id}>
                          <option
                            key={submenu.id}
                            className="p-2 rounded-md bg-slate-200"
                            value={submenu.id}
                            selected={submenu?.id === storeData?.parent_id}
                            disabled={submenu.id === storeData?.id}
                          >
                            {">"}
                            {submenu.name}
                          </option>
                          {Array.isArray(submenu?.megamenus) &&
                            submenu.megamenus.length > 0 &&
                            submenu?.megamenus.map((megamenu) => (
                              <option
                                key={megamenu.id}
                                className="px-2 rounded-md pl-4"
                                value={megamenu.id}
                                disabled
                              >
                                {">>"}
                                {megamenu.name}
                              </option>
                            ))}
                        </React.Fragment>
                      ))}
                  </React.Fragment>
                ))}
              </select>
            </div>
            <div className="md:col-span-1">
              <label htmlFor="name" className="font-semibold text-sm">
                Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={storeData?.name}
                onChange={handleInputChange}
                placeholder="name"
                className="form-control"
                required
              />
            </div>
            <div className="md:col-span-1">
              <label htmlFor="link" className="font-semibold text-sm">
                Link
              </label>
              <input
                type="text"
                id="link"
                name="link"
                value={storeData?.link}
                onChange={handleInputChange}
                placeholder="link"
                className="form-control"
                required
              />
            </div>
            <div className="md:col-span-1">
              <label htmlFor="grid_col" className="font-semibold text-sm">
                Cloumn
              </label>
              <input
                type="number"
                id="grid_col"
                name="grid_col"
                value={storeData?.grid_col}
                onChange={handleInputChange}
                placeholder="grid_col"
                className="form-control"
                required
              />
            </div>
            <div className="md:col-span-1">
              <label htmlFor="index" className="font-semibold text-sm">
                Index
              </label>
              <input
                type="number"
                id="index"
                name="index"
                onChange={handleInputChange}
                value={storeData?.index}
                placeholder="index"
                className="form-control"
                required
              />
            </div>
            <div className="md:col-span-1">
              <label htmlFor="color" className="font-semibold text-sm">
                Color
              </label>
              <input
                type="color"
                id="color"
                name="color"
                value={storeData?.color}
                onChange={handleInputChange}
                placeholder="color"
                className="form-control h-32"
                required
              />
            </div>
            <div className="md:col-span-2">
              <button
                disabled={isSubmitting}
                type="submit"
                className="btn-green w-full"
                data-ripple-light="true"
              >
                {isSubmitting ? <BeatLoader color="#fff" /> : "Update"}
              </button>
            </div>
          </div>
        </form>
      </TransitionsModal>
    </AdminLayout>
  );
};

export default MenuItem;
