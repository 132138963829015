import React, { useEffect, useState } from "react";
import { Box, IconButton } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import TransitionsModal from "../components/Modal";
import AdminLayout from "../layouts/AdminLayout";
import { BeatLoader } from "react-spinners";
import axios from "axios";
import { toast } from "react-toastify";
import { Icon } from "@iconify/react";
import { deleteApi, getApi, postApi } from "../config/configAxios";
import Swal from "sweetalert2";
import CustomLoader from "../components/CustomLoader";

const PhotoGallery = () => {
  const [data, setData] = useState([]);
  const [editData, setEditData] = useState({
    gallery_category_id: null,
    title: "",
    file: null,
    files: "",
  });
  const [loading, setLoading] = useState(true);
  const [openStore, setOpenStore] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [storeData, setStoreData] = useState({
    gallery_category_id: null,
    title: "",
    file: null,
    files: [],
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [missingFiles, setMissingFiles] = useState([]);
  const [category, setCategory] = useState([]);

  const fetchData = async () => {
    try {
      const res = await getApi(
        `${process.env.REACT_APP_API_BASE_URL}photo-gallery`
      );
      setData(res.data.data);
    } catch (err) {
      toast.error(err?.message);
    } finally {
      setLoading(false);
    }
  };
  const fetchCategory = async () => {
    try {
      const res = await getApi("gallery-category");
      setCategory(res?.data?.data);
    } catch (err) {
      toast.error(err?.message);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
    fetchCategory();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setStoreData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setStoreData((prevData) => ({ ...prevData, file: file }));
  };

  const handleMultipleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setStoreData((prevData) => ({ ...prevData, files: files }));
  };

  const handleRemoveFile = (index) => {
    const updatedFiles = [...storeData.files];
    updatedFiles.splice(index, 1);
    setStoreData((prevData) => ({ ...prevData, files: updatedFiles }));
  };

  const handleStore = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const formData = new FormData();
    formData.append("title", storeData.title);
    formData.append("file", storeData.file);
    formData.append("gallery_category_id", storeData.gallery_category_id);

    // Append each file separately
    storeData.files.forEach((file, index) => {
      formData.append(`files[${index}]`, file);
    });

    try {
      await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}store-photo-gallery`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      fetchData();
      setOpenStore(false);
      setStoreData({
        gallery_category_id: null,
        title: "",
        file: null,
        files: [],
      });

      toast.success("Data created successfully!");
    } catch (err) {
      toast.error(err?.response?.data?.error || "Creation failed");
    } finally {
      setIsSubmitting(false);
    }
  };

  // delete
  const handleDelete = async (id) => {
    Swal.fire({
      title: "Confirm delete",
      text: "Are you sure you want to delete this?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          try {
            await deleteApi(`delete-photo-gallery/${id}`)
              .then((res) => {
                toast.success("Data deleted successfully!");
              })
              .catch((err) => {
                toast.error("An error occurred while updating status");
              })
              .finally(() => {
                fetchData();
              });
          } catch (err) {
            toast.error(err?.response?.data?.error || "update failed");
          }
        } catch (error) {
          Swal.fire(
            "Error",
            "An error occurred while delete the user.",
            "error"
          );
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "The user was not deleted.", "info");
      }
    });
  };

  // edit
  const handleEdit = async (id) => {
    setOpenEdit(true);
    try {
      const res = await getApi(`find-photo-gallery/${id}`);
      setEditData(res.data.data);
      setStoreData(res.data.data);
    } catch (err) {
      toast.error(err?.message);
    }
  };
  // handleUpdate
  const handleUpdate = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const formData = new FormData();
    formData.append("title", storeData.title);
    formData.append("file", storeData.file);
    formData.append("old_files", editData.files);
    formData.append("gallery_category_id", storeData.gallery_category_id);

    // Append each file separately
    if (Array.isArray(storeData.files)) {
      // Append each file separately
      storeData.files.forEach((file, index) => {
        formData.append(`files[${index}]`, file);
      });
    }

    // Assuming missingFiles is a state variable
    missingFiles.forEach((file, index) => {
      formData.append(`missing_files[${index}]`, file);
    });

    try {
      await postApi(
        `${process.env.REACT_APP_API_BASE_URL}update-photo-gallery/${editData.id}`,
        formData
      );

      setOpenEdit(false);
      setStoreData({
        gallery_category_id: null,
        title: "",
        file: null,
        files: [],
      });

      fetchData(); // Assuming this function fetches the updated data
      toast.success("Data updated successfully!");
    } catch (err) {
      toast.error(err?.response?.data?.error || "Update failed");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleEditRemoveFile = (fileNameToRemove) => {
    // Check if editData.files is a string
    if (typeof editData.files === "string") {
      const updatedFiles = editData.files
        .split(",")
        .filter((fileName) => fileName !== fileNameToRemove);

      const missingFiles = editData.files
        .split(",")
        .filter((fileName) => fileName === fileNameToRemove);

      // Update the state for both updated and missing files
      setEditData((prevData) => ({
        ...prevData,
        files: updatedFiles.join(","),
      }));
      setMissingFiles((prevData) => [...prevData, ...missingFiles]);
    }
  };

  return (
    <AdminLayout title={"Photo gallery"}>
      {loading ? (
        <CustomLoader />
      ) : (
        <div>
          <div className="flex justify-between p-3">
            <h1 className="text-gray-700 font-medium">Photo gallery list</h1>
            <div>
              <button className="btn-indigo" onClick={() => setOpenStore(true)}>
                Add new
              </button>
            </div>
          </div>
          <div className="table-wrap">
            <table className="table-main shadow-md">
              <thead className="table-h">
                <tr>
                  <th className="table-h">SN</th>
                  <th className="table-h">Gallery thumbnail</th>
                  <th className="table-h">Category</th>
                  <th className="table-h">Title</th>
                  <th className="table-h">Action</th>
                </tr>
              </thead>
              <tbody className="table-b">
                {data.map((item, index) => (
                  <tr key={item.id}>
                    <td className="table-b">{index + 1}</td>
                    <td className="table-b">
                      <img
                        className="h-10 rounded-lg"
                        src={`${process.env.REACT_APP_API_FILE_URL}${item.file}`}
                        alt=""
                      />
                    </td>

                    <td className="table-b">{item?.gallery_category?.title}</td>
                    <td className="table-b">{item.title}</td>
                    <td className="table-b">
                      <Box display="flex">
                        <IconButton
                          className="mr-3"
                          onClick={() => handleDelete(item.id)}
                        >
                          <Delete className="text-red-500" />
                        </IconButton>
                        <IconButton
                          className="mr-3"
                          onClick={() => handleEdit(item.id)}
                        >
                          <Edit className="text-blue-500" />
                        </IconButton>
                      </Box>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {/* modal  */}

      <TransitionsModal title={"Add"} open={openStore} setOpen={setOpenStore}>
        <form className="" onSubmit={handleStore}>
          <div className="grid md:grid-cols-2 grid-cols-1 gap-6">
            <div className="md:col-span-2">
              <label
                htmlFor="gallery_category_id"
                className="font-semibold text-sm"
              >
                Select category
              </label>
              <select
                id="gallery_category_id"
                name="gallery_category_id"
                onChange={handleInputChange}
                className="form-control"
                required
              >
                <option className="p-2 rounded-md bottom-0" value="">
                  select
                </option>
                {category?.map((item) => (
                  <option
                    key={item.id}
                    className="p-2 rounded-md bottom-0"
                    value={item.id}
                  >
                    {item.title}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="grid md:grid-cols-2 grid-cols-1 gap-6">
            <div className="md:col-span-2">
              <label htmlFor="title" className="font-semibold text-sm">
                Title
              </label>
              <input
                type="text"
                id="title"
                name="title"
                onChange={handleInputChange}
                placeholder="title"
                className="form-control"
                required
              />
            </div>
            <div className="md:col-span-2">
              <label htmlFor="file" className="font-semibold text-sm">
                Gallery thumbnail
              </label>
              <input
                type="file"
                id="file"
                name="file"
                onChange={handleFileChange}
                placeholder="file"
                className="form-control"
                required
              />
            </div>
            <div>
              {storeData?.file && typeof storeData?.file !== "string" && (
                <>
                  <label htmlFor="file" className="font-semibold text-sm">
                    New Photo
                  </label>
                  <img
                    className="h-20 border border-dashed rounded-md p-3"
                    src={URL.createObjectURL(storeData?.file)}
                    alt="Preview"
                  />
                </>
              )}
            </div>
            <div className="md:col-span-2">
              <label htmlFor="files" className="font-semibold text-sm">
                Multiple images
              </label>
              <input
                type="file"
                id="files"
                name="files"
                onChange={handleMultipleFileChange}
                placeholder="files"
                className="form-control"
                required
                multiple
              />
            </div>
            <div className="md:col-span-2">
              {storeData?.files && typeof storeData?.files !== "string" && (
                <>
                  <label htmlFor="files" className="font-semibold text-sm">
                    Images
                  </label>
                  <div className="grid grid-cols-4 gap-1">
                    {storeData.files.map((file, index) => (
                      <div
                        key={index}
                        className="md:col-span-1 col-span-2 relative w-[88px]"
                      >
                        <img
                          className="h-20 border border-dashed rounded-md p-3 m-2"
                          src={URL.createObjectURL(file)}
                          alt={`Preview-${index}`}
                        />
                        <Icon
                          icon={"ic:round-close"}
                          onClick={() => handleRemoveFile(index)}
                          className="absolute top-3 right-2"
                        />
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
            <div className="md:col-span-2">
              <button
                disabled={isSubmitting}
                type="submit"
                className="btn-green w-full"
                data-ripple-light="true"
              >
                {isSubmitting ? <BeatLoader color="#fff" /> : "Submit"}
              </button>
            </div>
          </div>
        </form>
      </TransitionsModal>
      <TransitionsModal title={"Edit"} open={openEdit} setOpen={setOpenEdit}>
        <form className="" onSubmit={handleUpdate}>
          <div className="grid md:grid-cols-2 grid-cols-1 gap-6">
            <div className="md:col-span-2">
              <label
                htmlFor="gallery_category_id"
                className="font-semibold text-sm"
              >
                Select category
              </label>
              <select
                id="gallery_category_id"
                name="gallery_category_id"
                onChange={handleInputChange}
                placeholder="select heading"
                className="form-control"
                required
              >
                <option className="p-2 rounded-md bottom-0" value="">
                  select
                </option>
                {category?.map((item) => (
                  <option
                    key={item.id}
                    className="p-2 rounded-md bottom-0"
                    value={item.id}
                    selected={storeData?.gallery_category_id === item.id}
                  >
                    {item.title}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="grid md:grid-cols-2 grid-cols-1 gap-6">
            <div className="md:col-span-2">
              <label htmlFor="title" className="font-semibold text-sm">
                Title
              </label>
              <input
                type="text"
                id="title"
                name="title"
                value={storeData?.title}
                onChange={handleInputChange}
                placeholder="Charger votre fichier"
                className="form-control"
                required
              />
            </div>
            <div className="md:col-span-2">
              <label htmlFor="file" className="font-semibold text-sm">
                Gallery thumbnail
              </label>
              <input
                type="file"
                id="file"
                name="file"
                onChange={handleFileChange}
                placeholder="Charger votre fichier"
                className="form-control"
              />
            </div>
            <div className="grid grid-cols-2 gap-6">
              <div className="mr-3 ">
                <label htmlFor="file" className="font-semibold text-sm">
                  Old Photo
                </label>
                <img
                  className="h-20 border border-dashed rounded-md p-3"
                  src={`${process.env.REACT_APP_API_FILE_URL}${editData?.file}`}
                  alt=""
                />
              </div>
              <div>
                {storeData?.file && typeof storeData?.file !== "string" && (
                  <>
                    <label htmlFor="file" className="font-semibold text-sm">
                      New Photo
                    </label>
                    <img
                      className="h-20 border border-dashed rounded-md p-3"
                      src={URL.createObjectURL(storeData?.file)}
                      alt="Preview"
                    />
                  </>
                )}
              </div>
            </div>
            <div className="md:col-span-2">
              <label htmlFor="files" className="font-semibold text-sm">
                Multiple images
              </label>
              <input
                type="file"
                id="files"
                name="files"
                onChange={handleMultipleFileChange}
                placeholder="files"
                className="form-control"
                multiple
              />
            </div>

            <div className="md:col-span-2">
              {storeData?.files && typeof storeData?.files !== "string" && (
                <>
                  <label htmlFor="files" className="font-semibold text-sm">
                    New images
                  </label>
                  <div className="grid grid-cols-4 gap-1">
                    {storeData.files.map((file, index) => (
                      <div
                        key={index}
                        className="md:col-span-1 col-span-2 relative w-[88px]"
                      >
                        <img
                          className="h-20 border border-dashed rounded-md p-3 m-2"
                          src={URL.createObjectURL(file)}
                          alt={`Preview-${index}`}
                        />
                        <Icon
                          icon={"ic:round-close"}
                          onClick={() => handleRemoveFile(index)}
                          className="absolute top-3 right-2"
                        />
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
            <div className="md:col-span-2">
              <label htmlFor="files" className="font-semibold text-sm">
                Existing Images
              </label>
              <div className="grid grid-cols-4 gap-1">
                {editData?.files.split(",").map((fileName, index) => (
                  <div
                    key={index}
                    className="md:col-span-1 col-span-2 relative w-[88px]"
                  >
                    <img
                      className="h-20 border border-dashed rounded-md p-3 m-2"
                      src={`${process.env.REACT_APP_API_FILE_URL}${fileName}`}
                      alt={`File ${index}`}
                    />
                    <Icon
                      icon={"ic:round-close"}
                      onClick={() => handleEditRemoveFile(fileName)}
                      className="absolute top-3 right-2"
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className="md:col-span-2">
              <button
                disabled={isSubmitting}
                type="submit"
                className="btn-green w-full"
                data-ripple-light="true"
              >
                {isSubmitting ? <BeatLoader color="#fff" /> : "Update"}
              </button>
            </div>
          </div>
        </form>
      </TransitionsModal>
    </AdminLayout>
  );
};

export default PhotoGallery;
