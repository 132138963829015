import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import { Close } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import "./TransitionsModal.css"; // Import a CSS file for custom scrollbar styles

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%", // Adjusted width for responsiveness
  bgcolor: "#fff", // Changed to a valid background color
  boxShadow: "0px 0px 24px rgba(0, 0, 0, 0.2)", // Adjusted box shadow
  padding: 4,
  maxHeight: "95vh",
  overflow: "auto",
};

export default function TransitionsModal({ children, open, setOpen, title }) {
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box
            sx={{
              ...style,
              width: {
                xs: "95%",
                md: "50%",
              },
              borderRadius: 2,
            }}
          >
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"} 
            >
              <Typography
                id="transition-modal-title"
                sx={{ mb: 3 }}
                variant="h6"
                component="h2"
              >
                {title}
              </Typography>
              <IconButton
                style={{
                  position: "absolute",
                  top: "5px",
                  right: "5px",
                  color: "red",
                }}
                onClick={handleClose}
              >
                <Close />
              </IconButton>
            </Box>
            <Box>{children}</Box>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
